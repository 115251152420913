#rootSidebar {
    direction: rtl;
    display: flex;
    padding-top: 0px;
}

/* #NavContinerApp {
    position: absolute;
    right: 0px;
} */


#sideBarbgc2 {
    /* background-image: url(.../assets/img/side.jpg); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: #dc5d35e0;
    width: 240px;
}
#drawerSidebar {
    padding-top: 0;
}

#BTNSlideContiner {
    padding-top: 20px;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

#menuButtonSidebar {
    display: 'none';
}

#BTNSlide {
    width: 200px;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    color: #000;
    border-radius: 20px;
    background-color: #fff;
    height: 40px;
    cursor: pointer;
}

#BTNSlide:hover {
    transform: scale(1.04);
    background-color: #2594df;
    color: #fff !important;
    transition-duration: .3s;
    transition-timing-function: linear;
}

#BTNSlide:active {
    transform: scale(1);
    background-color: rgba(12, 46, 145, 0.8);
    color: #fbc85c !important;
    transition-duration: .3s;
    transition-timing-function: linear;
}

#BTNSlideName {
    margin-right: 15px;
    margin-left: 15px;
    font-size: 18px;
}


#drawerPaper {
    width: 240px;
}

#contentSidebar {
    flex-grow: 1;
    /* padding-top: 50px; */
}

@media (max-width: 480px) {
    #drawerSidebar {
        width: 240px;
        flex-shrink: 0;
    }

    #BTNSlideContiner {
        padding-top: 50;
        height: 90vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    #menuButtonSidebar {
        margin-right: 50px;
        z-index: 11;
    }
}